<template>
  <div>
    <app-breadcrumb />
    <form-wizard />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import FormWizard from './FormWizard.vue'

export default {
  components: {
    AppBreadcrumb,
    FormWizard,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
