<template>
  <div>
    <form-wizard
      color="#28C76F"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Kembali"
      next-button-text="Selanjutnya"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        title="Informasi Pribadi"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nama Lengkap"
                label-for="namaLengkap"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Lengkap"
                  rules="required"
                >
                  <b-form-input
                    id="namaLengkap"
                    v-model="nama"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nama Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="emailValue"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email Mitra"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="jeniskelamin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jenis Kelamin"
                  rules="required"
                >
                  <div class="d-flex flex-row">
                    <b-form-radio
                      v-model="jenis_kelamin"
                      plain
                      value="0"
                      class="mr-2"
                    >
                      Laki Laki
                    </b-form-radio>
                    <b-form-radio
                      v-model="jenis_kelamin"
                      plain
                      value="1"
                    >
                      Wanita
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NIK KTP"
                label-for="nik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  rules="required"
                >
                  <b-form-input
                    id="nik"
                    v-model="nik_ktp"
                    :state="errors.length > 0 ? false:null"
                    placeholder="NIK KTP"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Layanan"
                label-for="layanan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="layanan"
                  rules="required"
                >
                  <v-select
                    v-model="layanan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="text"
                    :options="vertikaloptions"
                    placeholder="Layanan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Provinsi"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label>Provinsi Pendaftaran</label>
                    <feather-icon
                      v-b-popover.hover.top="'Provinsi tempat calon mitra mendaftar'"
                      icon="HelpCircleIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>
                  <v-select
                    v-model="provinsiSelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="provinsiOptions"
                    placeholder="Provinsi"
                    :clearable="false"
                    @option:selected="getKota()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Kota"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label>Kota Pendaftaran</label>
                    <feather-icon
                      v-b-popover.hover.top="'Kota tempat calon mitra mendaftar'"
                      icon="HelpCircleIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>
                  <v-select
                    ref="kotaSelect"
                    v-model="kotaSelect"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="kotaOptions"
                    placeholder="Kota"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Informasi Kontak"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nomor HP"
                label-for="nomorHP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP"
                  rules="required"
                >
                  <b-input-group prepend="62">
                    <b-form-input
                      id="nomorHP"
                      v-model="no_hp"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor HP"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor WhatsApp"
                label-for="nomorWhatsApp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor WhatsApp"
                  rules="required"
                >
                  <b-input-group prepend="62">
                    <b-form-input
                      id="nomorWhatsApp"
                      v-model="no_wa"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor WhatsApp"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
                  <br>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat Lengkap Tinggal Sekarang"
                label-for="alamat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat Lengkap"
                  rules="required"
                >
                  <b-form-input
                    id="alamat"
                    v-model="alamat"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Alamat Lengkap"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Provinsi"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label>Provinsi Tinggal Sekarang</label>
                    <feather-icon
                      v-b-popover.hover.top="'Provinsi alamat tinggal mitra sekarang.'"
                      icon="HelpCircleIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>
                  <b-form-input
                    id="provinsi"
                    v-model="provinsi"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Provinsi"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Kota"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label>Kota Tinggal Sekarang</label>
                    <feather-icon
                      v-b-popover.hover.top="'Kota alamat tinggal mitra sekarang.'"
                      icon="HelpCircleIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>
                  <b-form-input
                    id="kota"
                    v-model="kota"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Kota"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Kecamatan"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label>Kecamatan Tinggal Sekarang</label>
                    <feather-icon
                      v-b-popover.hover.top="'Kecamatan alamat tinggal mitra sekarang.'"
                      icon="HelpCircleIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>
                  <b-form-input
                    id="kecamatan"
                    v-model="kecamatan"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Kecamatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Informasi Keluarga"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nomor HP Keluarga"
                label-for="no_hp_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor HP Keluarga"
                  rules="required"
                >
                  <b-input-group prepend="62">
                    <b-form-input
                      id="no_hp_keluarga"
                      v-model="no_hp_keluarga"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nomor HP Keluarga"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Keluarga"
                label-for="nama_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Keluarga"
                  rules="required"
                >
                  <b-form-input
                    id="nama_keluarga"
                    v-model="nama_keluarga"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nama Keluarga"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Status Keluarga"
                label-for="status_keluarga"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status Keluarga"
                  rules="required"
                >
                  <b-form-input
                    id="status_keluarga"
                    v-model="status_keluarga"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Status Keluarga"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="Data Pendukung"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Foto Surat Pernyataan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Surat Pernyataan"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSuratPernyataan"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSuratPernyataan"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="suratPernyataan = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Selfi Bersama Surat Pernyataan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Selfi Bersama Surat Pernyataan"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSelfiSuratPernyataan"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSelfiSuratPernyataan"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="selfiSuratPernyataan = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto KTP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto KTP"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropKTP"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoKTP"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoKTP = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Selfi Bersama KTP"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Selfi Bersama KTP"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSelfiKTP"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadSelfiKTP"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="selfiKTP = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto SKCK / Surat Pernyataan SKCK Sementara"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto SKCK / Surat Pernyataan SKCK Sementara"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSKCK"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoSKCK"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoSKCK = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto Profil"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto Profil"
                >
                  <vue-dropzone
                    id="dropzone"
                    ref="dropProfil"
                    :options="dropzoneOptions"
                    @vdropzone-complete="uploadFotoProfil"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoProfil = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Foto Sertifikat"
                >
                  <div class="d-flex justify-content-between">
                    <label>Foto Sertifikat</label>
                    <label
                      v-b-popover.hover.top="'Silahkan upload foto SIM, foto kendaraan, foto STNK, dan foto BPJS ketenagakerjaan disini.'"
                      class="text-primary"
                    >
                      Khusus untuk layanan ride dan kurir
                    </label>
                  </div>
                  <vue-dropzone
                    id="dropzone"
                    ref="dropSertifikat"
                    :options="dropzoneSertifikat"
                    @vdropzone-complete="uploadFotoSertifikat"
                    @vdropzone-error="dokumenError"
                    @vdropzone-removed-file="fotoSertifikat = undefined"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BInputGroup,
  VBPopover,
} from 'bootstrap-vue'
import { required, email } from '@validations'
/* eslint-disable no-unused-vars */
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vSelect from 'vue-select'
import { db, storage } from '@/firebase'
import DarkTogglerVue from '@/@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BInputGroup,
    VBPopover,
    ToastificationContent,
    vueDropzone: vue2Dropzone,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      vertikaloptions: [
        { value: 'vl01', text: 'Akupunktur' },
        { value: 'vl02', text: 'Auto' },
        { value: 'vl03', text: 'Barber' },
        { value: 'vl04', text: 'Bekam' },
        { value: 'vl05', text: 'Beauty' },
        { value: 'vl06', text: 'Cleaning' },
        { value: 'vl07', text: 'Massage' },
        { value: 'vl08', text: 'Cuci AC' },
      ],
      provinsiOptions: [],
      kotaOptions: [],
      provinsiSelect: '',
      kotaSelect: '',
      nama: '',
      emailValue: '',
      jenis_kelamin: '',
      nik_ktp: '',
      no_hp: '',
      no_wa: '',
      alamat: '',
      provinsi: '',
      kota: '',
      kecamatan: '',
      no_hp_keluarga: '',
      nama_keluarga: '',
      status_keluarga: '',
      layanan: [],
      area: '',
      error: false,
      suratPernyataan: undefined,
      selfiSuratPernyataan: undefined,
      fotoKTP: undefined,
      selfiKTP: undefined,
      fotoSKCK: undefined,
      fotoProfil: undefined,
      fotoSertifikat: [],
      required,
      email,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFilesize: 2,
        dictDefaultMessage: 'Taruh Foto Disini',
        uploadMultiple: false,
        maxFiles: 1,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
      },
      dropzoneSertifikat: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 250,
        thumbnailHeight: 250,
        addRemoveLinks: true,
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFilesize: 2,
        dictDefaultMessage: 'Taruh Foto Disini',
        uploadMultiple: true,
        maxFiles: 6,
        dictCancelUpload: 'Batalkan',
        dictCancelUploadConfirmation: 'Apakah anda yakin?',
        dictRemoveFile: 'Hapus File',
        dictFileTooBig: 'File terlalu besar ({{filesize}}MB). Maximal ukuran: {{maxFilesize}}MB.',
        dictInvalidFileType: 'Maaf, anda tidak bisa mengupload jenis file ini, hanya bisa .jpg, .jpeg, dan .png',
      },
      userData: {},
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    db.collection('region').get().then(querySnapshot => {
      const arr = []
      querySnapshot.forEach(doc => {
        arr.push({
          id: doc.id,
          text: doc.data().nama_provinsi,
        })
      })
      this.provinsiOptions = [...new Set(arr)]
    })
  },
  methods: {
    /* eslint-disable arrow-parens */
    /* eslint-disable arrow-body-style */
    /* eslint-disable no-lonely-if */
    async formSubmitted() {
      if (
        this.suratPernyataan !== undefined
        && this.selfiSuratPernyataan !== undefined
        && this.fotoKTP !== undefined
        && this.selfiKTP !== undefined
        && this.fotoSKCK !== undefined
        && this.fotoProfil !== undefined
        && this.suratPernyataan !== null
        && this.selfiSuratPernyataan !== null
        && this.fotoKTP !== null
        && this.selfiKTP !== null
        && this.fotoSKCK !== null
        && this.fotoProfil !== null
      ) {
        const vertikalLayanan = []

        const submitData = {
          nama: this.nama.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
          emailValue: this.emailValue,
          jenis_kelamin: parseInt(this.jenis_kelamin, 10),
          nik_ktp: this.nik_ktp,
          no_hp: `62${this.no_hp}`,
          no_wa: `62${this.no_wa}`,
          alamat: this.alamat,
          provinsi: this.provinsi,
          kota: this.kota,
          kecamatan: this.kecamatan,
          keluarga: {
            no_hp_keluarga: `62${this.no_hp_keluarga}`,
            nama_keluarga: this.nama_keluarga,
            status_keluarga: this.status_keluarga,
          },
          layanan: vertikalLayanan,
          status_aktif: 1,
          waktu_daftar: +new Date(),
          suratPernyataan: this.suratPernyataan,
          selfiSuratPernyataan: this.selfiSuratPernyataan,
          fotoKTP: this.fotoKTP,
          selfiKTP: this.selfiKTP,
          fotoSKCK: this.fotoSKCK,
          fotoProfil: this.fotoProfil,
          fotoSertifikat: this.fotoSertifikat ?? null,
          status_order: false,
          token: '',
          device_token: '',
        }
        db.collection('appConfig')
          .doc('web_hub')
          .collection('blacklist')
          .where('nik_ktp', '==', this.nik_ktp, true)
          .get()
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'NIK mitra ini telah diblokir !!',
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              db.collection('mitra').doc(`62${this.no_hp}`).set(submitData).then(() => {
                this.$router.push({ name: 'mitra' })
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil disubmit!',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
            }
          })
        if (this.no_hp.charAt(0) === 0) {
          this.no_hp = this.no_hp.substring(1)
        }

        this.layanan.forEach(el => {
          vertikalLayanan.push({
            area: this.kotaSelect.id,
            vertikal: el.value,
            leader: this.userData.leader_id,
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap cek dokumen anda kembali!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },

    dokumenError(file, message, xhr) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },

    getKota() {
      this.$refs.kotaSelect.clearSelection()
      db.collection('region').doc(this.provinsiSelect.id).collection('kota').get()
        .then(querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            arr.push({
              id: doc.id,
              text: doc.data().nama_kota,
            })
          })
          this.kotaOptions = [...new Set(arr)]
        })
    },

    async uploadSuratPernyataan(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/suratPernyataan.${filetype}`)
      await ref.put(file, metadata)

      this.suratPernyataan = await ref.getDownloadURL()
    },
    async uploadSelfiSuratPernyataan(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/selfiSuratPernyataan.${filetype}`)
      await ref.put(file, metadata)

      this.selfiSuratPernyataan = await ref.getDownloadURL()
    },
    async uploadFotoKTP(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoKTP.${filetype}`)
      await ref.put(file, metadata)

      this.fotoKTP = await ref.getDownloadURL()
    },
    async uploadSelfiKTP(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/selfiKTP.${filetype}`)
      await ref.put(file, metadata)

      this.selfiKTP = await ref.getDownloadURL()
    },
    async uploadFotoSKCK(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoSKCK.${filetype}`)
      await ref.put(file, metadata)

      this.fotoSKCK = await ref.getDownloadURL()
    },
    async uploadFotoProfil(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/fotoProfil.${filetype}`)
      await ref.put(file, metadata)

      this.fotoProfil = await ref.getDownloadURL()
    },
    async uploadFotoSertifikat(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mitra/62${this.no_hp}/${file.name}`)
      await ref.put(file, metadata)

      const sertifikatUrl = await ref.getDownloadURL()

      this.fotoSertifikat.push(sertifikatUrl)
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
